/** @jsx jsx */
import { forwardRef, useContext } from "react"
import { jsx, Box } from "theme-ui"

import { InterfaceContext } from "../../contexts/InterfaceContext"

const NavigationToggle = forwardRef((props, ref) => {
  const { toggleNav } = useContext(InterfaceContext)

  return (
    <Box
      ref={ref}
      as="button"
      title="Toggle Menu"
      sx={{
        appearance: "none",
        width: 19,
        height: 18,
        m: 0,
        p: "10px",
        color: "primary",
        bg: "transparent",
        boxSizing: "content-box",
        border: 0,
        "&:focus": {
          outline: 0,
        },
        "&:hover": {
          color: "highlight",
        },
      }}
      onClick={toggleNav}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="12"
        fill="currentcolor"
        viewBox="0 0 20 12"
        sx={{
          display: "block",
          margin: 0,
        }}
      >
        <path d="M0 0h20l-1.667 2H0zM0 5h17l-1.417 2H0zM0 10h13l-1.083 2H0z" />
      </svg>
    </Box>
  )
})

export default NavigationToggle
