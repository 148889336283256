import React, { useContext, useEffect, useState } from "react"
import { Box, Text, Flex, Input } from "theme-ui"

import { StoreContext } from "../../contexts/StoreContext"
import Button from "../Button"

const LineItem = ({ id, title, variant, quantity }) => {
  const [itemQuantity, setItemQuantity] = useState(quantity)
  const { updateLineItem, removeLineItem, isUpdating } = useContext(
    StoreContext
  )

  useEffect(() => {
    setItemQuantity(quantity)
  }, [quantity])

  const handleQuantityChange = async (event) => {
    if (isUpdating) return

    const { value } = event.target

    // Make sure the quantity is always at least 1.
    const safeValue = Math.max(Number(value), 0)

    // No need to update if the value hasn’t updated.
    if (value === quantity) {
      return
    }

    // If the field is empty, update the state but don’t do anything else.
    if (value === "") {
      setItemQuantity(value)
      return
    }

    // Otherwise, trigger the loading state and set the quantity in state.
    setItemQuantity(safeValue)

    // If the quantity is set to 0, remove the item.
    if (safeValue === 0) {
      removeLineItem(id)
      return
    }

    // If we get here, update the quantity.
    updateLineItem(id, safeValue)
  }

  return (
    <Box
      key={id}
      sx={{
        marginBottom: 24,
        transition: "opacity 0.3s ease",
        opacity: isUpdating ? 0.1 : 1,
      }}
    >
      <Flex>
        <Box sx={{ flexBasis: 95 }}>
          <Box
            as="img"
            src={`https://res.cloudinary.com/adammonster/image/fetch/w_300/${variant.image.src}`}
            alt=""
            sx={{
              width: "100%",
              maxWidth: "100%",
              verticalAlign: "middle",
            }}
          />
        </Box>
        <Flex
          sx={{
            paddingLeft: 24,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ marginBottom: 3 }}>
            <Text variant="small" sx={{ fontWeight: "bold" }}>
              {title} / {variant.title}
            </Text>
            <Text variant="small">${variant.price}</Text>
          </Box>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Input
              type="number"
              min="1"
              step="1"
              defaultValue={itemQuantity}
              onChange={(event) => handleQuantityChange(event)}
              sx={{
                width: 60,
                borderColor: "muted",
                marginRight: 2,
                "&:focus": {
                  borderColor: "primary",
                  boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
                  outline: "none",
                },
              }}
            />
            <Button variant="remove" as="a" onClick={() => removeLineItem(id)}>
              Remove
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default LineItem
