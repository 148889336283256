import React, { useContext } from "react"
import { Box } from "theme-ui"

import { InterfaceContext } from "../contexts/InterfaceContext"

const Overlay = ({ isVisible = false }) => {
  const { toggleCart } = useContext(InterfaceContext)

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.8)",
        transition: isVisible
          ? "opacity 0.5s"
          : "opacity 0.5s, visibility 0s 0.5s",
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? "visible" : "hidden",
      }}
      onClick={toggleCart}
    />
  )
}

export default Overlay
