/** @jsx jsx */
import { useContext } from "react"
import { jsx, Flex, Box, Link } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"

import { InterfaceContext } from "../../contexts/InterfaceContext"

const NavLink = ({
  to,
  color = "primary",
  hoverColor = "highlight",
  children,
  ...props
}) => {
  return (
    <Link
      as={GatsbyLink}
      to={to}
      variant="nav"
      sx={{
        transition: "color 0.3s ease",
        color,
        "&:hover": {
          color: hoverColor,
        },
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

const CloseButton = () => {
  const { toggleNav } = useContext(InterfaceContext)

  return (
    <Box
      as="button"
      sx={{
        border: 0,
        outline: 0,
        background: "none",
        position: "absolute",
        top: "5px",
        width: "10px",
        height: "18px",
        left: "10px",
        color: "#fff",
        cursor: "pointer",
        padding: "10px",
        boxSizing: "content-box",
        transform: "rotate(90deg)",
        "&:hover": {
          color: "highlight",
        },
      }}
      onClick={toggleNav}
    >
      <svg
        fill="currentColor"
        width="20"
        height="12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M0 0h20l-1.667 2H0zM0 5h17l-1.417 2H0zM0 10h13l-1.083 2H0z" />
        </g>
      </svg>
    </Box>
  )
}

const NavigationModal = () => {
  const { toggleNav, isNavOpen } = useContext(InterfaceContext)
  const navLinkStyles = { color: "#fff", fontSize: 2, my: "5px", py: "10px" }

  return (
    <Flex
      as="nav"
      bg="primary"
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        top: -1,
        transform: isNavOpen ? "translateX(0%)" : "translateX(-100%)",
        transition: "transform 0.75s",
        width: ["100%", "100%", 525],
        willChange: "transform",
        zIndex: "1000",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <NavLink to="/episode-002" onClick={toggleNav} sx={navLinkStyles}>
        Buy
      </NavLink>
      <NavLink to="/story" onClick={toggleNav} sx={navLinkStyles}>
        Story
      </NavLink>
      <NavLink to="/brand" onClick={toggleNav} sx={navLinkStyles}>
        Brand
      </NavLink>
      <NavLink to="/faq" onClick={toggleNav} sx={navLinkStyles}>
        Faq
      </NavLink>

      <CloseButton />
    </Flex>
  )
}

export default NavigationModal
