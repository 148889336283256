/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

import Container from "../Container"
import CartIndicator from "../CartIndicator"

import Navigation from "./Navigation"
import NavigationToggle from "./NavigationToggle"
import LogoLink from "./LogoLink"

const Header = () => {
  return (
    <Container
      bg="#fff"
      sx={{
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "#e9e9e9",
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: [50, 74] }}
      >
        <Flex
          sx={{
            flexGrow: [1, 3],
            flexBasis: ["unset", "0%"],
            justifyContent: ["center", "flex-start"],
            alignItems: "center",
            order: [2, 1],
          }}
        >
          <LogoLink height={[20, 28]} />
        </Flex>
        <Flex
          sx={{
            flexGrow: 1,
            flexBasis: ["0%", "unset"],
            justifyContent: ["flex-start", "center"],
            alignItems: "center",
            order: [1, 2],
          }}
        >
          <Navigation sx={{ display: ["none", "block"] }} />
          <NavigationToggle sx={{ display: ["block", "none"] }} />
        </Flex>
        <Flex
          sx={{
            flexGrow: [1, 3],
            flexBasis: "0%",
            justifyContent: "flex-end",
            alignItems: "center",
            order: 3,
          }}
        >
          <CartIndicator height={[24, 30]} />
        </Flex>
      </Flex>
    </Container>
  )
}

export default Header
