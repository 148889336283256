/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"

import brandLogos from "../images/logos.svg"
import NavLink from "./NavLink"
import Container from "./Container"

const Footer = ({ ...footerProps }) => {
  return (
    <Container bg="#020202" as="footer" {...footerProps}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          flexDirection: ["column", "column", "row"],
          py: ["20px", "20px", "40px"],
          mb: ["20px", "20px", "40px"],
          borderBottom: "1px solid #4c5a67",
        }}
      >
        <div sx={{ p: 2 }}>
          <img
            sx={{
              width: [160, 160, 200],
              height: [36, 36, 45],
            }}
            src={brandLogos}
            alt=""
          />
        </div>
        <div sx={{ mx: "auto", mb: "20px" }} />
        <Navigation align="center" color="#fff" />
      </Box>
      <FooterNavigation />
      <Box
        as="p"
        sx={{
          color: "#4c5a67",
          fontSize: "12px",
          padding: "10px 0 50px",
          textAlign: "center",
        }}
      >
        2020 &copy; Adam Monster Pty Ltd. All Rights Reserved
      </Box>
    </Container>
  )
}

const Navigation = () => {
  const styles = {
    color: "#fff",
    fontSize: [0, 0, 1],
    py: "5px",
    px: ["5px", "5px", "10px"],
    mx: ["5px", "5px", "15px"],
  }

  return (
    <Flex
      as="nav"
      sx={{
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <NavLink to="/episode-002" sx={styles}>
        Buy
      </NavLink>
      <NavLink to="/story" sx={styles}>
        Story
      </NavLink>
      <NavLink to="/brand" sx={styles}>
        Brand
      </NavLink>
      <NavLink to="/faq" sx={styles}>
        Faq
      </NavLink>
    </Flex>
  )
}

const FooterNavigation = () => {
  const styles = {
    color: "#fff",
    fontSize: [0, 0, 2],
    py: "5px",
    px: ["5px", "5px", "10px"],
    mx: "5px",
    textDecoration: "none",
  }

  return (
    <Flex
      as="nav"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        mb: ["20px", "20px", "30px"],
      }}
    >
      <NavLink to="/shipping" variant="simple" sx={styles}>
        Shipping
      </NavLink>
      <NavLink to="/refunds" variant="simple" sx={styles}>
        Refunds
      </NavLink>
      <NavLink to="/privacy" variant="simple" sx={styles}>
        Privacy
      </NavLink>
      <NavLink to="/terms" variant="simple" sx={styles}>
        Terms
      </NavLink>
    </Flex>
  )
}

export default Footer
