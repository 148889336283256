import React, { useContext } from "react"
import { Box, Text, Flex } from "theme-ui"

import { InterfaceContext } from "../../contexts/InterfaceContext"
import { StoreContext } from "../../contexts/StoreContext"
import Header from "./Header"
import LineItem from "./LineItem"
import Totals from "./Totals"

const Cart = () => {
  const { isCartOpen } = useContext(InterfaceContext)
  const { checkoutData, isUpdating } = useContext(StoreContext)

  const { lineItems } = checkoutData

  return (
    <Flex
      bg="background"
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        top: -1,
        transform: isCartOpen ? "translateX(0%)" : "translateX(100%)",
        transition: "transform 0.75s",
        width: ["100%", "100%", 525],
        willChange: "transform",
        zIndex: "1000",
        flexDirection: "column",
      }}
    >
      <Header />
      {lineItems.length > 0 ? (
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 24,
            flexGrow: 1,
          }}
        >
          <Box>
            {lineItems.map(({ id, ...rest }) => (
              <LineItem key={id} id={id} {...rest} />
            ))}
          </Box>
          <Totals />
        </Flex>
      ) : (
        <Box
          sx={{
            px: 4,
            py: 6,
            transition: "opacity 0.3s ease",
            opacity: isUpdating ? 0.1 : 1,
          }}
        >
          <Text sx={{ textAlign: "center" }}>Your cart is empty</Text>
        </Box>
      )}
    </Flex>
  )
}

export default Cart
