export default {
  breakpoints: ["40em", "56em", "64em"],
  colors: {
    text: "#020202",
    background: "#fff",
    primary: "#020202",
    secondary: "#c5d7b0",
    muted: "#e9e9e9",
    gray: "#8e979c",
    highlight: "#ee3245",
    darken: "darken",
  },
  sizes: {
    container: 1680,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, sans-serif",
    fancyHeading: "Harbour, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 64, 96],
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: ".2em",
    },
    title: {
      variant: "text.heading",
      textAlign: "center",
      mt: 0,
      mb: 4,
      fontSize: [4, 4, 6],
      color: "#13293d",
    },
    subtitle: {
      variant: "text.heading",
      fontSize: ["10px", 0],
      color: "highlight",
      fontWeight: "400",
      textTransform: "uppercase",
      fontFamily: "fancyHeading",
      mb: 1,
    },
    paragraph: {
      fontSize: [0, 1, 2],
      lineHeight: "1.5",
      mt: 0,
      mb: 3,
      color: "darken",
      "& a": {
        color: "text",
        textDecoration: "underline",
      },
      "& a:hover": {
        textDecoration: "none",
      },
    },
    body: {
      fontSize: [0, 1, 2],
      color: "text",
    },
    small: {
      fontSize: 0,
      lineHeight: 1.9,
    },
    blockParagraph: {
      variant: "text.paragraph",
      fontSize: ["inherit", "inherit", "inherit"],
      color: "inherit",
      lineHeight: 1.9,
    },
  },
  links: {
    bold: {
      fontWeight: "bold",
    },
    nav: {
      fontWeight: "400",
      fontFamily: "fancyHeading",
      textTransform: "uppercase",
      textDecoration: "none",
      fontSize: 1,
      py: "5px",
      px: ["5px", "5px", "10px"],
      mx: ["5px", "5px", "15px"],
    },
    simple: {
      variant: "links.navlink",
      fontFamily: "body",
      textTransform: "none",
    },
    episode: {
      fontWeight: "400",
      fontFamily: "fancyHeading",
      textTransform: "uppercase",
      color: "highlight",
      fontSize: "10px",
      display: "inline-block",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "highlight",
      textDecoration: "none",
      transition: "border-color 0.2s ease-in-out, color 0.2s ease-in-out",
      lineHeight: 1,
      "&:hover": {
        color: "primary",
        borderBottomColor: "primary",
      },
    },
  },
  buttons: {
    primary: {
      color: "background",
      bg: "text",
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
    highlight: {
      color: "background",
      bg: "highlight",
      transition: "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
      "&:hover": {
        bg: "primary",
      },
      "&:disabled": {
        bg: "primary",
        opacity: "0.25",
        cursor: "default",
      },
    },
    standard: {
      fontFamily: "body",
      variant: "buttons.highlight",
      fontSize: [0],
      cursor: "pointer",
      py: 3,
    },
    text: {
      variant: "text.small",
      color: "text",
      bg: "transparent",
      borderRadius: 0,
      display: "inline",
      padding: 0,
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "text",
      textDecoration: "none",
      transition: "border-color 0.2s ease-in-out, color 0.2s ease-in-out",
      lineHeight: 1,
      cursor: "pointer",
      "&:hover": {
        color: "highlight",
        borderBottomColor: "highlight",
      },
    },
    remove: {
      variant: "buttons.text",
      fontSize: "10px",
      borderBottomColor: "transparent",
      color: "gray",
    },
    icon: {
      variant: "buttons.text",
      bg: "transparent",
      color: "gray",
      borderBottomWidth: 0,
      "&:hover": {
        color: "text",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      backgroundColor: "background",
      backgroundAttachment: "fixed",
      backgroundPosition: "50% 0",
      backgroundSize: ["auto", "auto", "cover"],
    },
  },
}
