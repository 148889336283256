import React, { useContext } from "react"
import { Box, Text, Flex } from "theme-ui"

import { StoreContext } from "../../contexts/StoreContext"
import Button from "../Button"

const Totals = () => {
  const { checkoutData, adding } = useContext(StoreContext)

  const { subtotalPrice, webUrl } = checkoutData

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: "space-between",
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "gray",
          py: 2,
          mx: 2,
          transition: "opacity 0.3s ease",
          opacity: adding ? 0.1 : 1,
        }}
      >
        <Text variant="small" sx={{ fontWeight: "bold" }}>
          Subtotal
        </Text>
        <Text variant="small" sx={{ fontWeight: "bold" }}>
          ${subtotalPrice}
        </Text>
      </Flex>
      <Text variant="small" sx={{ py: 2, mx: 2, marginBottom: 3 }}>
        Shipping &amp; taxes calculated at checkout
      </Text>
      <Button variant="standard" as="a" href={webUrl} sx={{ display: "block" }}>
        Check out
      </Button>
    </Box>
  )
}
export default Totals
