/** @jsx jsx */
import { jsx, Box, Link } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"

const LogoLink = ({ height }) => {
  return (
    <Link
      as={GatsbyLink}
      aria-label="Home page"
      color="primary"
      to="/"
      sx={{
        display: "inline-block",
        height,
        transition: "color 0.3s ease",
        "&:hover": {
          color: "highlight",
        },
      }}
    >
      <Box as="svg" sx={{ height }} fill="currentColor" viewBox="0 0 38 26">
        <path d="M22.8 15.5l-1 1.5H7.5l3.3-4.7h18.7L38 0H9L7 3.1h9.9l.5-.5H33l-1 .8h-.8l-.6.9h-21l-2.1 3h5.8l.3-.4h18.5l-.9.8h-3l-1.1 1.5H8.7L1 19.9h.1L1 20h23l4.9-7c-1.2.4-4.3 1.3-6.1 2.5M5 26h17.9l2.1-3H6.9L5 26zM2 23.1L0 26h3.2l2.3-2.9H2z" />
      </Box>
    </Link>
  )
}

export default LogoLink
