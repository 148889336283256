import React, { useContext } from "react"
import { Heading, Flex } from "theme-ui"

import { InterfaceContext } from "../../contexts/InterfaceContext"
import Button from "../Button"

const Header = () => {
  const { toggleCart } = useContext(InterfaceContext)

  return (
    <Flex
      sx={{
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        height: 70,
        px: 24,
      }}
    >
      <Button
        variant="icon"
        onClick={toggleCart}
        sx={{
          position: "absolute",
          left: 24,
          background: "none",
          border: 0,
          py: 10,
          px: 0,
          cursor: "pointer",
          color: "gray",
          "&:hover": {
            color: "primary",
          },
        }}
      >
        <svg
          width="38"
          height="13"
          viewBox="0 0 38 13"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.70710678 6.183l5.14644661-5.14644661c.19526215-.19526214.19526215-.51184463 0-.70710678-.19526215-.19526215-.51184463-.19526215-.70710678 0L.29289321 6.183l5.8535534 5.8535534c.19526215.1952621.51184463.1952621.70710678 0 .19526215-.1952622.19526215-.5118446 0-.7071068L1.70710678 6.183z"
            fill="currentColor"
          />
          <path fill="currentColor" d="M1.25 5.683v1h35.90625v-1z" />
        </svg>
      </Button>
      <Heading as="h3">My Cart</Heading>
    </Flex>
  )
}
export default Header
