/** @jsx jsx */
import { jsx, Link } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"

const NavLink = ({
  to,
  color = "primary",
  hoverColor = "highlight",
  children,
  ...props
}) => {
  return (
    <Link
      as={GatsbyLink}
      to={to}
      variant="nav"
      sx={{
        transition: "color 0.3s ease",
        color,
        "&:hover": {
          color: hoverColor,
        },
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

export default NavLink
