/** @jsx jsx */
import { useContext } from "react"

import { jsx, Text, Box, Button } from "theme-ui"
import { InterfaceContext } from "../contexts/InterfaceContext"
import { StoreContext } from "../contexts/StoreContext"

const CartIndicator = ({ height }) => {
  const { toggleCart } = useContext(InterfaceContext)
  const { checkoutData } = useContext(StoreContext)
  const lineItemsCount = checkoutData.lineItems.reduce(
    (total, item) => total + item.quantity,
    0
  )

  return (
    <Button
      aria-label={`Shopping cart with ${lineItemsCount} items`}
      onClick={toggleCart}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bg: "transparent",
        p: 0,
        border: 0,
        outline: 0,
        transition: "color 0.3s ease",
        cursor: "pointer",
        color: "primary",
        appearance: "none",
        "&:focus": {
          outline: 0,
        },
        "&:hover": {
          color: "highlight",
        },
      }}
    >
      {lineItemsCount > 0 && (
        <Text
          as="span"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            fontSize: [10, 10, 12],
            fontWeight: "bold",
            size: [20, 20, 24],
            backgroundColor: "primary",
            color: "#fff",
          }}
        >
          {lineItemsCount}
        </Text>
      )}
      <Box as="svg" sx={{ height }} fill="currentColor" viewBox="0 0 32 32">
        <path d="M12 23.286a2.283 2.283 0 0 0-2.274 2.285A2.283 2.283 0 0 0 12 27.857a2.292 2.292 0 0 0 2.286-2.286A2.292 2.292 0 0 0 12 23.286zM5.143 5L4 7.286h3.429l1.513 3.19H16L14 13l-3.867-.012 1.41 2.972L10 18.76a2.21 2.21 0 0 0-.286 1.097A2.292 2.292 0 0 0 12 22.143h12.571l1.143-2.286H12.48a.283.283 0 0 1-.286-.286l.035-.137 1.028-1.863h8.514c.858 0 1.612-.468 2-1.177l4.092-7.417a1.146 1.146 0 0 0-1.006-1.691H9.954L8.88 5H5.143zm18.286 18.286a2.283 2.283 0 0 0-2.275 2.285 2.283 2.283 0 0 0 2.275 2.286 2.292 2.292 0 0 0 2.285-2.286 2.292 2.292 0 0 0-2.285-2.285z" />
      </Box>
    </Button>
  )
}

export default CartIndicator
