/** @jsx jsx */
import { forwardRef } from "react"
import { jsx, Flex, Link } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"

const NavLink = ({ to, children }) => {
  const color = "primary"
  const hoverColor = "highlight"

  return (
    <Link
      as={GatsbyLink}
      to={to}
      variant="nav"
      sx={{
        transition: "color 0.3s ease",
        color,
        "&:hover": {
          color: hoverColor,
        },
      }}
    >
      {children}
    </Link>
  )
}

const Navigation = forwardRef((props, ref) => {
  return (
    <Flex
      ref={ref}
      as="nav"
      sx={{
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      {...props}
    >
      <NavLink to="/episode-002">Buy</NavLink>
      <NavLink to="/story">Story</NavLink>
      <NavLink to="/brand">Brand</NavLink>
      <NavLink to="/faq">Faq</NavLink>
    </Flex>
  )
})

export default Navigation
