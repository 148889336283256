/** @jsx jsx */
import { jsx, Button as ThemeButton } from "theme-ui"
import { forwardRef } from "react"

const Button = forwardRef(({ variant = "primary", sx, ...props }, ref) => (
  <ThemeButton
    ref={ref}
    type="button"
    sx={{
      appearance: "none",
      display: "inline-block",
      textAlign: "center",
      lineHeight: "inherit",
      textDecoration: "none",
      fontSize: "inherit",
      fontWeight: "bold",
      m: 0,
      px: 3,
      py: 2,
      border: 0,
      borderRadius: 100,
      variant: `buttons.${variant}`,
      ...sx,
    }}
    {...props}
  />
))

export default Button
