import React, { useContext } from "react"
import { ThemeProvider, Flex, Box } from "theme-ui"
import { Global } from "@emotion/react"

import { InterfaceContext } from "../contexts/InterfaceContext"
import theme from "../theme"
import "../fonts/harbour.css"
import "../fonts/poppins.css"

import NavigationModal from "./header/NavigationModal"
import Cart from "./cart"
import Header from "./header"
import Footer from "./Footer"
import Overlay from "./Overlay"

const Layout = ({ children }) => {
  const { isCartOpen, toggleNav } = useContext(InterfaceContext)

  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={() => ({
          ".gatsby-image-wrapper img": {
            transition: "opacity 500ms ease 0s!important",
          },
          ".gatsby-image-wrapper > div": {
            transition: "opacity 500ms ease 0s!important",
          },
        })}
      />
      <Flex
        sx={{
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Box
          as="main"
          sx={{
            width: "100%",
            flex: "1 1 auto",
          }}
        >
          {children}
        </Box>
        <Footer />
      </Flex>
      <Cart />
      <NavigationModal />
      <Overlay isVisible={isCartOpen} />
    </ThemeProvider>
  )
}

export default Layout
