/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const Container = ({ children, ...containerProps }) => {
  return (
    <Box {...containerProps}>
      <Box
        sx={{
          maxWidth: "container",
          mx: "auto",
          px: [10, 20, "10%"],
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Container
